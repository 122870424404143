@import '../../../base/assets/scss/_base/config';

.m-channel-description{
  &__text{
    text-align: left;
    line-height: 24px;
    padding: 56px 0 0 20px;
    color: $batcave;

    &.cell {
      padding-top: 60px;
      padding-left: 0;
    }
  }

  &--shopping{
    .m-channel-description {
      &__inner{
        border-top: 8px solid $batcave;
      }
      &__text {
        margin: 0;
        padding-left: 0;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 1.75;
      }
    }
  }
}
